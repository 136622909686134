import {BorgoLoginResult} from '@sparbanken-syd/borgo-types'
import {LocalStorageService} from './local-storage.service'
import {BehaviorSubject, map, NEVER, Observable, of, switchMap} from 'rxjs'
import {environment} from '../../environments/environment'
import {HttpClient, HttpHeaders} from '@angular/common/http'

export class RequestService {

  /**
   * Keep this public so that others can react when there are
   * a new valid token to work with.
   */
  public token$ = new BehaviorSubject<BorgoLoginResult | null>(null)

  protected borgoAccessToken: BorgoLoginResult = {token: '', refreshToken: '', expires: Date.now() - 1001} as any

  private borgoTokenStorage: LocalStorageService<BorgoLoginResult | null>

  constructor(protected http: HttpClient, tokenName: string) {
    this.borgoTokenStorage = new LocalStorageService<BorgoLoginResult | null>(tokenName, this.token$)
    this.checkBorgoToken()
  }

  /**
   * Checks auth and return the type T using GET
   * @param path - Exactly what you want, _including_ the leading / e.g. /users
   */
  protected getBorgo<T>(path: string): Observable<T> {
    const url = `${environment.apiUrl}${path}`
    return this.checkLogin().pipe(
      switchMap(token => {
        const headers = new HttpHeaders({'X-SPB-Borgo-Token': token})
        return this.http.get<T>(`${url}`, {headers}).pipe()
      })
    )
  }

  /**
   * Delete something with Borgo
   * @param path - Exactly what you want, _including_ the leading / e.g. /users
   */
  protected deleteBorgo(path: string): Observable<void> {
    const url = `${environment.apiUrl}${path}`
    return this.checkLogin().pipe(
      switchMap(token => {
        const headers = new HttpHeaders({'X-SPB-Borgo-Token': token})
        return this.http.delete<void>(`${url}`, {headers}).pipe()
      })
    )
  }

  /**
   * Checks auth and return the type T using PUT sending the data D
   * @param path - Exactly what you want, _including_ the leading / e.g. /users
   * @param data - Anything you can send as a JSON payload
   * @private
   */
  protected putBorgo<T, D>(path: string, data: D): Observable<T> {
    const url = `${environment.apiUrl}${path}`
    return this.checkLogin().pipe(
      switchMap(token => {
        const headers = new HttpHeaders({'X-SPB-Borgo-Token': token})
        return this.http.put<T>(`${url}`, data, {headers}).pipe()
      })
    )
  }

  protected refresh(): Observable<BorgoLoginResult> {
    return of(this.borgoAccessToken)
  }

  protected reset(): void {
    this.borgoTokenStorage.clear()
    this.borgoAccessToken = {expires: 0, refreshToken: '', sessionId: '', token: ''}
    this.token$.next(null)
  }

  /**
   * If we have a stored token we pick it up.
   */
  protected checkBorgoToken(): void {
    this.borgoAccessToken = this.borgoTokenStorage.get() || {
      token: '',
      refreshToken: '',
      expires: Date.now() - 1001,
      sessionId: ''
    }
    // If token still valid send it to our token$
    this.checkLogin().subscribe({
      next: () => this.token$.next(this.borgoAccessToken)
    })
  }

  protected checkLogin(): Observable<string> {
    // If the token has more than one second to live, just use the
    // existing token.
    if (this.borgoAccessToken.expires - 1000 > Date.now()) {
      return of(this.borgoAccessToken.token)
    }
    // Otherwise if we do not have a refresh token
    // We simply stop here.
    if (!this.borgoAccessToken.refreshToken) {
      return NEVER
    }
    // Otherwise, create a new one
    return this.refresh().pipe(
      map(result => {
        this.borgoAccessToken = result
        this.token$.next(result)
        return result.token
      })
    )
  }
}
